// colors
$col0: #fff;
$col1: #fafafa;
$col2: #dcdcdc;
$col3: rgba(0, 0, 0, 0.6);
$col4: rgba(0, 0, 0, 0.8);
$col5: #9a2024;
$col6: #498049;
$col7: rgba(0, 0, 0, .2);
$col8: rgba(0, 0, 0, .14);
$col9: rgba(0, 0, 0, .12);
$col10: #673ab7;
$col11: rgba(0, 0, 0, 0.54);
$col12: #789bd4;